import React, { useState } from "react"
import ValidateOTPAPI from "../../APIServices/ValidateOTP";
import { useLocation } from 'react-router-dom';
import './Otp.css'

function Otp() {

   const location = useLocation();
   const apiData = location.state;

   const [pinCode, setPinCode] = useState(null);
   const [otpValidation, setOtpValidation] = useState(false)
   const [ButtonText,setButtonText] = useState("Confirm!")
   const [errorMessage,setErrorMessage] = useState(null)

   const handleSubmit = async () => {

      setButtonText("Please wait!");
      const result = await ValidateOTPAPI(localStorage.getItem("mobile"), pinCode, apiData?.serverRef || "");
      
      if (result.statusCode === 200) {
         setOtpValidation(true)
      }else{         
         setErrorMessage(result?.message || "OTP is invalid or expired. Please try again.");
         setButtonText("Confirm!")
      }
   }
   return (
      <>
         <header id="main-header">
            <div class="main-header">
               <div class="container-fluid">
                  <div class="row">
                     <div class="col-sm-12">
                        <nav class="navbar navbar-expand-lg navbar-light p-0">
                           <a class="navbar-brand" href="index.html"> <img class="img-fluid logo" height={"60px"} width={"200px"} src="../../Giro-KJ.svg"
                              alt="streamit" /> </a>

                        </nav>
                        <div class="nav-overlay"></div>
                     </div>
                  </div>
               </div>
            </div>
         </header>
         {
            otpValidation ?
            <section className="sign-in-page">

            <div className="container">
               <div className="row justify-content-center align-items-center height-self-center">
                  <div className="col-lg-5 col-md-12 align-self-center">
                     <div className="sign-user_card ">
                        <div className="sign-in-page-data">
                           <div className="sign-in-from w-100 m-auto">
                              <h2 className="mb-4 text-center">You have successfully subscribed to Giro Gamez.</h2>
                              <h6 className="mt-4 text-center">Download the Giro Gamez TV app from PlayStore and enter the subscribed mobile number to get access. Happy Gaming!</h6>
                              
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         :
         <section className="sign-in-page">

            <div className="container">
               <div className="row justify-content-center align-items-center height-self-center">
                  <div className="col-lg-5 col-md-12 align-self-center">
                     <div className="sign-user_card ">
                        <div className="sign-in-page-data">
                           <div className="sign-in-from w-100 m-auto">
                              
                              <h6 className="mb-3 text-center">Please enter your received PIN Code below:</h6>
                              <div className="form-group">
                                 <input type="text" className="form-control mb-0 custom-input" value={pinCode} onChange={(e) => setPinCode(e.target.value)} placeholder="Enter PIN Code" required />

                              {errorMessage ? <div className="text-center mx-auto"> <small style={{ marginTop: "-2%", color:"red" }}>{errorMessage}</small> </div> : ""}
                              </div>

                              <div className="text-center">
                                 {
                                    pinCode === null || pinCode === "" || pinCode === undefined ?
                                       <button type="button" className="btn btn-custom2" style={{ color: "white" }} disabled>Confirm!</button>
                                       :
                                       <button type="button" className="btn btn-custom"  style={{ color: "white" }} onClick={() => { handleSubmit() }}>{ButtonText}</button>

                                 }
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         }
         <section className="sign-in-page">

            <div className="container">
               <div className="row justify-content-center align-items-center height-self-center">
                  <div className="col-lg-5 col-md-12 align-self-center">
                     <div className="sign-user_card ">
                        <div className="sign-in-page-data">
                           <div className="sign-in-from w-100 m-auto">
                              <h6 className="mb-3 text-center">Please enter your received PIN Code below:</h6>
                              <div className="form-group">
                                 <input type="text" className="form-control mb-0 custom-input" value={pinCode} onChange={(e) => setPinCode(e.target.value)} placeholder="Enter PIN Code" required />
                              </div>

                              <div className="text-center">
                                 {
                                    pinCode === null || pinCode === "" || pinCode === undefined ?
                                       <button type="button" className="btn btn-custom"><h3 style={{ color: "white" }} disabled>Comfirm!</h3></button>
                                       :
                                       <button type="button" className="btn btn-custom"><h3 style={{ color: "white" }} onClick={() => { handleSubmit() }}>Comfirm!</h3></button>

                                 }
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <div className="copyright py-2">
            <div className="container-fluid">
               <p className="mb-0 text-center font-size-14 text-body">On subscribing to this service. Subscription would be renewed automatically until you unsubscribe.</p>
            </div>
         </div>
      </>
   )
}

export default Otp
