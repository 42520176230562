import React, { useState, useEffect} from "react"
import './Subscribe.css'
import GenerateOTPAPI from "../../APIServices/GenerateOTP";
import { useNavigate } from "react-router-dom";
import CheckAPIVersion from '../../APIServices/APIVersion';


function Subscribe() {
   const [mobile, setMobile] = useState(null);
   const [isFormSubmitted, setIsFormSubmitted] = useState(false)
   const [ButtonText, setButtonText] = useState("Subscribe Now!")
   const [errorMessage,setErrormessage] = useState(null)
   const navigate = useNavigate();

   function isValidNumber(number) {
      return number.startsWith('94') && number.length == 11;
   }

   const CheckVersion = async (token) =>{
      const result = await CheckAPIVersion(token)
        if(localStorage.getItem("APIVersion")!=result.version){
          localStorage.setItem("APIVersion",result.version)
          window.location.reload()
        }
        
    }

   const unsubscribe = () => {
      navigate('/unsubscribe');
   }

   const handleSubmit = async () => {

      setIsFormSubmitted(true)

      if (!isValidNumber('94' + mobile)) {
         return
      }

      setButtonText("Please wait!");

      const result = await GenerateOTPAPI('94' + mobile)

      if (result.statusCode === 200) {

         localStorage.setItem("mobile", `94${mobile}`);

         let data = { serverRef: result?.body?.serverRef || "" };
         navigate("/verify", { state: data});

      }else{
         setErrormessage(result?.message || "Something went wrong")
         setTimeout(()=>{
            setErrormessage(null)
         },3000)
         setButtonText("Subscribe Now!")
      }
   }

   useEffect(() => {
      CheckVersion();
   }, [])

   return (
      <>
         <header id="main-header">
            <div className="main-header">
               <div className="container-fluid">
                  <div className="row">
                     <div className="col-sm-12">
                        <nav className="navbar navbar-expand-lg navbar-light p-0">
                           <a className="navbar-brand" href="index.html"> <img className="img-fluid logo" height={"60px"} width={"200px"} src="../../Giro-KJ.svg"
                              alt="streamit" /> </a>

                        </nav>
                        <div className="nav-overlay"></div>
                     </div>
                  </div>
               </div>
            </div>
         </header>
         <section className="sign-in-page">

            <div className="container">
               <div className="row justify-content-center align-items-center height-self-center">
                  <div className="col-lg-5 col-md-12 align-self-center">
                     <div className="sign-user_card ">
                        <div className="sign-in-page-data">
                           <div className="sign-in-from w-100 m-auto">
                              <h6 className="mb-3 text-center">Enter your mobile number to subscribe to Giro gamez</h6>
                              
                              <div className="mb-3" style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                 <div style={{ width: "27%" }}  >

                                    <input type="countrycode" name="countrycode" tabindex="1" className="form-control mb-0 custom-input" value={"94"} placeholder="94" disabled />
                                 </div>
                                 <div style={{ width: "83%", marginLeft: "20px" }} >
                                    <   input onChange={(e) => setMobile(e.target.value)} tabindex="1" className="form-control mb-0 custom-input" placeholder="Enter Your 9 Digit Mobile Number" required />
                                    {isFormSubmitted && !isValidNumber('94' + mobile) ? <small style={{ marginTop: "-2%", color: "red" }}> Enter 9 digit mobile number</small> : ""}
                                    {errorMessage ? <small style={{ marginTop: "-2%", color: "red" }}>{errorMessage}</small>:null}

                                 </div>
                              </div>


                              <div className="text-center">
                                 {
                                    !isValidNumber('94' + mobile) ?
                                       <button type="button" className="btn btn-custom2" style={{ color: "white", backgroundColor: "#E50914 !important" }} onClick={() => { handleSubmit() }} disabled={true}>{ButtonText}</button>
                                       :
                                       <button type="button" className="btn btn-custom" style={{ color: "white", backgroundColor: "#E50914 !important" }} onClick={() => { handleSubmit() }}>{ButtonText}</button>

                                 }
                              </div>
                              
                              <div className="tnc">
                                 <p style={{color: "#ffffff", fontSize: "15px", textAlign: 'justify'}}>*On subscribing to this service, you will be charged Rs. 5/day. Subscription would be renewed automatically until you unsubscribe.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </>
   )
}

export default Subscribe
