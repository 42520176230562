
const CheckAPIVersion = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/app-version", requestOptions);
        const result_1 = await response.json();
        return result_1;
    } catch (error) {
        return console.log('error', error);
    }
}
export default CheckAPIVersion;