import './App.css'

import { AuthProvider, RequireAuth } from 'react-auth-kit'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Subscribe from './pages/Subscribe/Subscribe'
import Otp from './pages/Otp/Otp';
import Unsubscribe from './pages/Unsubscribe';

function App() {
  return (
    <AuthProvider
      authType={'cookie'}
      authName={'_auth'}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === 'https:'}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/verify" element={<Otp />} />
          <Route path="/" element={<Subscribe />} />
          {/* <Route path="/unsubscribe" element={<Unsubscribe />} /> */}
         

        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App
