
const ValidateOTPAPI = async (mobile,otp,serverRef) => {

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
        mobile:mobile,
        pin: otp,
        serverRef: serverRef
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/users/submit-pin", requestOptions);
        const result_1 = await response.json();
        return result_1;
    } catch (error) {
        return console.log('error', error);
    }
}
export default ValidateOTPAPI;